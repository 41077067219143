import React from "react"
/*import Link from 'gatsby-link'*/
import { graphql } from "gatsby"
import get from "lodash/get"
import Img from "gatsby-image"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import PageHero from "../components/pageHero"
import ScrollAnimation from "react-animate-on-scroll"
import styles from "./fundraising.module.css"

class FundraisingIndex extends React.Component {
  render() {
    const siteTitle = get(this, "props.data.site.siteMetadata.title")
    const allSections = get(this, "props.data.allContentfulBlogPost.edges")
    const hero = allSections.filter(
      a => a.node.tags && a.node.tags.includes("Hero")
    )[6]
    const innerSections = allSections.filter(a => a !== hero)
    return (
      <Layout>
        <Helmet title={`Fundraising | ${siteTitle}`} />
        <PageHero hero={hero.node} />

        <section>
          <div className="container-fluid">
            <div className="wrapper fundraising">
              <h3>WAYS TO FUNDRAISE</h3>
              <div className=" row justify-content-center ">
                {innerSections.map((item, index) => {
                  return (
                    <div
                      className={`col-md-6 col-lg-4 mt-5 `}
                      key={item.node.slug}
                    >
                      <ScrollAnimation animateIn="fadeInUp">
                        {" "}
                        <div className="image-item shadow-bottom">
                          <div className="row justify-content-between">
                            <div className={`col-md-12 `}>
                              {" "}
                              <Img
                                alt={item.node.title}
                                sizes={item.node.heroImage.sizes}
                              />
                            </div>
                            <div
                              className={`col-md-12 ${styles.fundraise_content}`}
                            >
                              <h3>{item.node.title}</h3>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    item.node.description.childMarkdownRemark
                                      .html
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </ScrollAnimation>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default FundraisingIndex

export const pageQuery = graphql`
  query fundraisingQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(
      filter: { tags: { eq: "Fundraising" } }
      sort: { fields: [sort], order: DESC }
    ) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            sizes(maxWidth: 1920, resizingBehavior: SCALE) {
              ...GatsbyContentfulSizes_withWebp
            }
          }
          photos {
            title
            sizes(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulSizes_withWebp
            }
          }
          body {
            childMarkdownRemark {
              html
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
